import React from "react"
import classNames from "classnames"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import ReportDisclaimer from "../components/ReportDisclaimer/ReportDisclaimer"
import styles from "./privacy-policy.module.css"

const PrivacyPolicyPage = () => (
  <SiteLayout>
    <SEO title="Privacy policy" />
    <section className={styles.sectionWrapper}>
      <div className={classNames("container", styles.container)}>
        <h1 className={styles.heading}>
          <strong className="text-uppercase">Privacy Policy</strong>
        </h1>

        <p>Our commitment to protect your privacy</p>
        <p>
          We understand how important it is to protect your personal
          information. We recognise that any personal information we collect
          about you will only be used for the purposes we have collected it or
          as allowed by law. It is important to us that you are confident that
          any personal information you provide will be treated in a way that
          ensures you full and proper protection.
        </p>
        <p>
          Our commitment in respect of personal information is to abide by the
          Australian Privacy Principles for the protection of personal
          information, as set out in the Privacy Act and any other relevant
          laws.
        </p>
        <p>
          SuburbGrowth.com.au, as part of Select Residential Property may in its
          absolute discretion choose to provide the name and email address of
          any users to its wholly owned affiliated companies. The provision of
          this information will be for marketing purposes only. These affiliated
          companies can be found on Select Residential Property website{" "}
          <a
            href="https://www.selectresidentialproperty.com.au/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.selectresidentialproperty.com.au
          </a>
        </p>

        <h4 className={styles.subHeading}>
          <strong>Application Disclaimer</strong>
        </h4>
        <p>
          The information in this website is for general information and private
          use only and should not be taken as constituting professional advice
          from the website owner.
        </p>
        <p>
          The owner of the website is Select Residential Property Pty Ltd. This
          company is a business that harnesses state of the art technology, data
          capture and online user experience to provide high quality data and
          analysis research tools to assist them in making property investment
          decisions.
        </p>
        <p>
          SuburbGrowth is not a property or financial adviser. You should
          consider seeking independent property, legal, financial, taxation or
          other advice to check how the information provided by this application
          relates to your unique circumstances.
        </p>
        <p>
          SuburbGrowth provided prediction at the suburb level, not at an
          individual property level. It is not liable for any loss caused,
          whether due to negligence or otherwise arising from the use of, or
          reliance on, the information provided directly or indirectly, by use
          of this application.
        </p>
        <p>
          The main objective of this application is for educational and
          potential insight purposes at the suburb level and due care has been
          considered in its development. The owner and agents for the owner are
          unable to accept any liability or responsibility whatsoever for any
          error or omission or any loss or damage of any kind sustained by any
          person or entity arising from the use of this information. It is
          recommended that users always seek independent professional advice
          relevant to their specific circumstances before acting on the
          information in those reports, calculator and tools.
        </p>
        <p>
          All reasonable care has been taken in preparing and designing the
          reports, calculators and tools; however, SuburbGrowth.com.au provides
          no warranties and makes no representation that the information
          provided by the calculators and tools is appropriate for you to solely
          rely on or to take action with. Any medium term prediction or forecast
          is often subject to change or potential inaccuracies. Additional
          research is always warranted.
        </p>

        <h4 className={styles.subHeading}>
          <strong>Property Prediction Report Disclaimer</strong>
        </h4>
        <ReportDisclaimer />
      </div>
    </section>
  </SiteLayout>
)

export default PrivacyPolicyPage
