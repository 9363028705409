import React, { Fragment } from "react"

const ReportDisclaimer = () => (
  <Fragment>
    <p>
      This report is intended for private use only (non-commercial) for the
      person who generated it.
    </p>
    <p>
      The forecast predictions are produced and owned by the research group
      Select Residential Property Pty Ltd (the proprietors of
      suburbgrowth.com.au) using proprietary sophisticated computer modelling
      combining market data variables and statistical techniques measuring a
      series of demand and supply variables within residential property market
      activity. Suburbgrowth.com.au collects millions of data points monthly to
      create its forecasts each month. Given the volume of data collected, some
      data sourced may be inaccurate or incomplete or unsuitable for its
      intended use.
    </p>
    <p>
      These forecasts are general suburb level predictions only. For clarity,
      they are based on all housing stock, by suburb, or on all unit/apartment
      stock, by suburb, across the total suburb. Past performance is not a
      reliable indicator of future performance.
    </p>
    <p>
      The private user of this report needs to consider that each individual
      property (house or unit) within a suburb has its very own unique
      characteristics, strengths, weakness, issues, risks, potential, appeal,
      threats or current condition which will directly impact its future value
      either positively or negatively, irrespective of how the suburb, by houses
      or units, is anticipated to potentially perform.
    </p>
    <p>
      The private user of this report also needs to consider that predicted
      forecasts by suburb can also be impacted by events that may not already be
      evident in the variables assessed, the data collected or the modelled
      assumptions used. As such these new events could impact the predictions
      also. Examples of such events may include but are not limited to: market
      risk, development risk, legislation risk, event risk, and government
      intervention risk.
    </p>
    <p>
      The information in this report is general information only and is not
      based on the objectives, financial situation, or needs of any particular
      person. This report should not be relied upon to make any individual
      property purchasing or selling decision or take any direct action with.
      Further due diligence must be undertaken, including using the services of
      professional qualified and licensed advisors before making any financial
      or property related decisions. Select Residential Property Pty Ltd or its
      related bodies corporate will not be responsible or be liable for any loss
      or damage or claim regardless of nature or kind related to or arising from
      any party's action which relied on the information in this report.
    </p>
  </Fragment>
)

export default ReportDisclaimer
